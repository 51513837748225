import logoImg from './images/logo_whitebg.png';

import './App.css';
import React from "react"
import database from './firebase/firebase';

export default class App extends React.Component { 
  state = { //"application wide options"      
      message: undefined,
      youtubeStreamUrl: undefined      
  }

  componentDidMount() // called when component will be added to DOM
  {
    console.log("component did mount");      
    this.fetchInitialData();
        
    database.ref("settings").on("child_changed", (snapshot) => {       
            
      console.log("setting changed: "+snapshot.key+" -> "+ snapshot.val())
      console.log("snapshot: ",snapshot);                  
        this.setState({
          [snapshot.key]: snapshot.val(),
        });      
    })          
  }

  fetchInitialData() {
    console.log("fetchInitialData");
    database.ref("settings").once("value").then((snapshot) => {            
      this.setState({ 
         message: snapshot.val().message,
         youtubeStreamUrl: snapshot.val().youtubeStreamUrl
      });
    })
    .catch(e => {
      console.log("error fetching data: ", e);
      console.log("retrying in 1 sec");
      setTimeout(() => {
        this.fetchInitialData();  
      }, 1000);      
    });
  }

  componentDidUpdate(prevProps, prevState) //called when component updates
  {                
    console.log("component did update");
    console.log("prevState", prevState);
  }        

  componentWillUnmount() //called when component will be removed from DOM
  {
      console.log("component will unmount")      
      database.ref("settings").off();
  }

  render() {      
    console.log("render, message: "+this.state.message);  
      return (    
        <div className="background">          
          <div className="container">            
            <img src={logoImg} className="logoImg"/>                     
            <div className="youtubeVideoContainer">            
              <iframe className="youtubeVideo" src={this.state.youtubeStreamUrl} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowFullScreen wmode='opague'></iframe>                            
            </div>            
            <p className="message">{this.state.message}</p>                
          </div>          
      </div>
      )
  }
}